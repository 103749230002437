import MessageFormat from "@messageformat/core"
import { MessageFormatOptions, createMessageFormat } from "./createMessageFormat"
import { normalizeLocale } from "../helpers/normalizeLocale"

export interface StringSet {
  [stringId: string]: string
}

export type StringSetBatch = Array<{ locale: string, strings: StringSet }>

export type StringMsg = (params: {}) => string

export interface StringMsgSet {
  [stringId: string]: StringMsg
}

export interface StringMsgSetMap {
  [locale: string]: StringMsgSet
}

export interface LocalizedString {
  stringID?: string
  parameters?: { [name: string]: string | number }
  defaultString?: string
}

export interface MessageFormatMap {
  [locale: string]: MessageFormat
}

/**
 * Translator that holds localization context and localized strings.
 *
 * Immutable to be used as React context.
 */
export class Translator {
  public readonly defaultLocale = "en_US"

  public readonly fallbackValue = "[no text]"

  public readonly formatErrorValue = "[format error]"

  public readonly compileErrorValue = "[compile error]"

  public readonly locale: string

  public readonly fallbackLocales: string[]

  public readonly debug: boolean

  public readonly stringIdFallback: boolean

  public readonly messageFormatMap: MessageFormatMap

  private strings: StringMsgSetMap = {}

  public constructor(
    locale: string = "en_US",
    fallbackLocales: string[] = [],
    debug = false,
    stringSetBatch: StringSetBatch = [],
    stringIdFallback = false,
    messageFormatOptions: MessageFormatOptions = { projectionType: "view" }
  ) {
    this.locale = locale
    this.fallbackLocales = fallbackLocales
    this.debug = debug
    this.stringIdFallback = stringIdFallback

    const messageFormatMap: MessageFormatMap = {}
    const allLocales = [locale, ...fallbackLocales]
    allLocales.forEach((lc) => {
      const normalizedLc = normalizeLocale(lc)
      messageFormatMap[normalizedLc] = createMessageFormat(normalizedLc, messageFormatOptions)
    })
    this.messageFormatMap = messageFormatMap

    // Go last
    this.batchLoadStrings(stringSetBatch)
  }

  /**
   * Lookup formatted string value using LocalizedString object.
   */
  public lookup(str: LocalizedString) {
    return this.lookupStringValue(str.stringID, str.parameters, str.defaultString)
  }

  /**
   * Lookup formatted string value by string ID.
   *
   * This method is trying to find string in primary locale first and then scans
   * for string in fallback locales.
   *
   * @deprecated Just for compatibility
   */
  public lookupStringValue(stringId: string | undefined, params?: {}, defaultString?: string) {
    if (stringId) {
      // try to find compiled message among different locales
      const targetLocale = this.lookupStringLocale(stringId)

      // format found compiled message in specific locale and return result
      if (targetLocale) {
        const message = this.strings[targetLocale][stringId]
        return this.safeFormat(targetLocale, stringId, message, params)
      }
    }

    // compile fallback string on the fly and format the message using current locale
    if (typeof defaultString === "string") {
      return this.formatString(defaultString, params)
    }

    // default fallback, undefined is used as indicator that string is not available
    return this.debug || this.stringIdFallback
        ? `${this.fallbackValue} [${stringId}]`
        : this.fallbackValue
  }

  /**
   * Format existing string with parameters.
   */
  private formatString(formattedString: string, params?: {}) {
    const normalizedLocale = normalizeLocale(this.locale)
    const message = this.safeCompile(normalizedLocale, formattedString, formattedString)
    return this.safeFormat(normalizedLocale, formattedString, message, params)
  }

  /**
   * Lookup for compiled message with specified string id in different locales
   * and return first available.
   */
  private lookupStringLocale(stringId: string): string | undefined {
    // try translation from primary locale first
    if (this.strings[this.locale]
      && this.strings[this.locale][stringId]
    ) {
      return this.locale
    }

    // try fallback locales if provided
    for (const fallbackLocale of this.fallbackLocales) {
      if (fallbackLocale !== this.locale
        && this.strings[fallbackLocale]
        && this.strings[fallbackLocale][stringId]
      ) {
        return fallbackLocale
      }
    }
  }

  /**
   * Batch load strings for each locale.
   */
  private batchLoadStrings(stringSetBatch: StringSetBatch) {
    stringSetBatch.forEach(({ locale, strings }) => {
      this.loadStrings(locale, strings)
    })
  }

  /**
   * Load strings for specific locale.
   */
  private loadStrings(locale: string, strings: StringSet) {
    // normalize once for all strings
    const normalizedLocale = normalizeLocale(locale)

    // initialize storage
    if (!this.strings[locale]) {
      this.strings[locale] = {}
    }

    // compile strings
    for (const id in strings) {
      this.strings[locale][id] = this.safeCompile(normalizedLocale, id, strings[id])
    }
  }

  /**
   * Compile message safely without crashing the application.
   */
  private safeCompile(locale: string, id: string, message: string) {
    try {
      return this.messageFormatMap[locale].compile(message)
    } catch (e) {
      console.error(`Unable to compile string \"${id}\" on locale \"${locale}\": ${String(e)}`)
      return () => this.compileErrorValue
    }
  }

  /**
   * Format message safely without crashing the application.
   */
  private safeFormat(locale: string, id: string, message: StringMsg, params?: {}) {
    try {
      return message(params || {}) + (this.debug ? ` [${id}]` : "")
    } catch (e) {
      console.error(`Unable to format string \"${id}\" on locale \"${locale}\": ${String(e)}`)
      return this.debug || this.stringIdFallback
        ? `${this.formatErrorValue} [${id}]`
        : this.formatErrorValue
    }
  }

  public toJSON() {
    // This class is not supposed to be stringified so we just return an opaque string
    // Otherwise it pollutes the test snapshots with its internals
    return "Translator {}"
  }
}
