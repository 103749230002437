import React from "react"

import { LocalizedString } from "../../i18n/Translator"
import { FlexLayout } from "../../layout/flex/FlexLayout"
import { Button } from "../../button/Button"
import { LocalizedText } from "../../text/LocalizedText"
import { AddHotKey } from "../../keyboard/AddHotKey"
import { ViewportCapsContext } from "../../layout/viewport/ViewportCapsContext"
import { HotKeyCode } from "../../keyboard/HotKeyCode"

export interface ConfirmationFrameProps {
  /**
   * Primary button label.
   */
  primaryButtonLabel?: LocalizedString

  /**
   * Primary button hot key.
   */
  primaryButtonHotKey?: HotKeyCode

  /**
   * Secondary button label.
   */
  secondaryButtonLabel?: LocalizedString

  /**
   * Secondary button hot key.
   */
  secondaryButtonHotKey?: HotKeyCode

  /**
   * Primary button click callback.
   */
  onPrimaryButtonClick?: () => void

  /**
   * Secondary button click callback.
   */
  onSecondaryButtonClick?: () => void

  /**
   * Controls whether the primary button is disabled.
   */
  primaryButtonDisabled?: boolean
}

/**
 * This component renders a frame for confirmation purposes.
 * Children will be displayed on top, and two confirmation buttons, one primary and the other
 * secondary, will be presented at the bottom.
 */
export const ConfirmationFrame: React.FunctionComponent<ConfirmationFrameProps> =
  React.memo(({
    children,
    primaryButtonLabel,
    primaryButtonHotKey,
    secondaryButtonLabel,
    secondaryButtonHotKey,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    primaryButtonDisabled,
  }) => (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => {
          // a ~little~ bit of responsiveness to make layout better on desktop platform
          const twoButtonsVisible = primaryButtonLabel && secondaryButtonLabel
          const smallAtMost = viewportCaps.hSizeAtMost("sm")
          const buttonPanelSize = (smallAtMost && twoButtonsVisible) ? "5vu" : "3vu"
          const direction = smallAtMost ? "column" : "row-reverse"
          const buttonFontSize = viewportCaps.hSizeAtMost("md") ? "md" : "xl"

          return (
            <FlexLayout direction="column">
              <FlexLayout.Item>
                {children}
              </FlexLayout.Item>

              {(primaryButtonLabel || secondaryButtonLabel) && (
                <FlexLayout.Item size={buttonPanelSize}>
                  <FlexLayout
                    direction={direction}
                    padding="sm"
                    backgroundColor="white"
                  >
                    {primaryButtonLabel && (
                      <FlexLayout.Item>
                        <Button
                          flex={true}
                          variant="primary"
                          size={buttonFontSize}
                          onClick={onPrimaryButtonClick}
                          disabled={primaryButtonDisabled}
                        >
                          <AddHotKey keyCode={primaryButtonHotKey}>
                            <LocalizedText {...primaryButtonLabel} />
                          </AddHotKey>
                        </Button>
                      </FlexLayout.Item>
                    )}

                    {primaryButtonLabel && secondaryButtonLabel && (
                      <FlexLayout.Spacer size="sm" />
                    )}

                    {secondaryButtonLabel && (
                      <FlexLayout.Item>
                        <Button
                          flex={true}
                          variant="secondary"
                          size={buttonFontSize}
                          onClick={onSecondaryButtonClick}
                        >
                          <AddHotKey keyCode={secondaryButtonHotKey}>
                            <LocalizedText {...secondaryButtonLabel} />
                          </AddHotKey>
                        </Button>
                      </FlexLayout.Item>
                    )}
                  </FlexLayout>
                </FlexLayout.Item>
              )}
            </FlexLayout>
          )
        }}
      </ViewportCapsContext.Consumer>
    ))

ConfirmationFrame.displayName = "ConfirmationFrame"
