import { CustomFormatter } from "@messageformat/core"
import { ARBinID, BinID } from "../../bin/BinID"

export type BinIDPartToRead = "all" | "floor" | "aisle" | "bin" | "aisle_and_bin"

export const binForView: CustomFormatter = (value, locale, arg) => {
  return value
}

export const binForVoice: CustomFormatter = (value, lc, arg) => {
  const locale = new Intl.Locale(lc)
  // no handling of non English language for now
  if (locale.language !== "en") {
    return value
  }
  // @ts-ignore type of value is set as unknown for CustomFormatter
  const binID = BinID.from(value)
  if (binID) {
    return traditionalBinForVoice(binID, locale, arg)
  }
  // @ts-ignore type of value is set as unknown for CustomFormatter
  const arBinID = ARBinID.from(value)
  if (arBinID) {
    return arBinForVoice(arBinID)
  }
  return value
}

const arBinForVoice = (binID: ARBinID) => {
  return binID.toString()
}

const traditionalBinForVoice = (binID: BinID, locale: Intl.Locale, arg: string | null = "all") => {
  const buf: string[] = []
  // Details may change
  if (arg === "all" || arg === "floor") {
    buf.push(`floor ${phoneticalize(binID.floor)}`)
  }
  if (arg === "all" || arg === "aisle" || arg === "aisle_and_bin") {
    buf.push(`aisle ${phoneticalize(binID.mod)} ${phoneticalize(binID.aisle)}`)
  }
  if (arg === "all" || arg === "bin" || arg === "aisle_and_bin") {
    buf.push(`bin ${phoneticalize(binID.shelf)} ${phoneticalize(binID.bin)}`)
  }

  return buf.join(" ")
}

export function phoneticalize(str: string): string {
  return str.split("").map((char) => phoneticAlphabetMap[char]).join(" ")
}

const phoneticAlphabetMap: Record<string, string> = {
  A: "Alfa",
  B: "Bravo",
  C: "Charlie",
  D: "Delta",
  E: "Echo",
  F: "Foxtrot",
  G: "Golf",
  H: "Hotel",
  I: "India",
  J: "Juliett",
  K: "Kilo",
  L: "Lima",
  M: "Mike",
  N: "November",
  O: "Oscar",
  P: "Papa",
  Q: "Quebec",
  R: "Romeo",
  S: "Sierra",
  T: "Tango",
  U: "Uniform",
  V: "Victor",
  W: "Whiskey",
  X: "X-ray",
  Y: "Yankee",
  Z: "Zulu",

  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine"
}
