import React from "react"

import { TestProps } from "../helpers/TestProps"

import { ProductInfo } from "./ProductInfo"

import { MainProductBox } from "./productbox/MainProductBox"
import { Size } from "../helpers/parseSize"
import { TwoColumnProductBox } from "./productbox/TwoColumnProductBox"

export type ProductBoxVariant =
    "main"  // All elements in a column and default variant
    | "two-column"  // With image on the left. Suitable for when its height is limited.

export interface ProductBoxProps extends TestProps {
  productInfo: ProductInfo

  variant?: ProductBoxVariant

  // This will hide the image area completely.
  // Otherwise, if the URL is broken or not provided, a placeholder image
  // is shown instead.
  hideImage?: boolean

  /**
   * This is mainly to handle the case where ProductBox is too tall
   * (like with a long title).
   *
   * fit: default. Each component (title, image, etc) may take a proportial height
   * but may be clipped due to height restrictions.
   *
   * auto: stack all components with their auto calculated heights. May overflow
   * the available height. You can wrap the ProductBox in a ScrollableLayout in this case.
   */
  heightMode?: "fit" | "auto"

  /**
   * To fine tune the image height.
   * Only available when variant=main
   *
   * When heightMode=auto, an absolute size (in px or rem) must be given. Default is 5rem.
   *
   * When heightMode=fit, an absolute size (in px or rem) or fractional size (in fr)
   * can be given. The fractional is relative to the area for the title, the brand and
   * the binding (2fr in total). Default is 2fr.
   */
  imageHeight?: Size
}

export interface ProductBoxStrings {
  packOfN?: string
}

export class ProductBox extends React.PureComponent<ProductBoxProps> {
  public static defaultProps = {
    variant: "main",
    hideImage: false,
    heightMode: "fit",
  }

  public static defaultStrings?: ProductBoxStrings

  public readonly emptyStrings: ProductBoxStrings = {}

  public render() {
    let variantComponent
    const strings = ProductBox.defaultStrings || this.emptyStrings

    switch (this.props.variant) {
      case "two-column":
        variantComponent = (
          <TwoColumnProductBox
            productInfo={this.props.productInfo}
            strings={strings}
            testID={this.props.testID}
            heightMode={this.props.heightMode}
          />
        )
        break
      case "main":
      default:
        variantComponent = (
          <MainProductBox
            productInfo={this.props.productInfo}
            hideImage={this.props.hideImage}
            strings={strings}
            testID={this.props.testID}
            heightMode={this.props.heightMode}
            imageHeight={this.props.imageHeight}
          />
        )
    }

    return (
      variantComponent
    )
  }
}
