import React from "react"
import {
  DocumentType,
  MediaType,
  PDFChildrenCallback,
  PrintDocFlowBaseOptions,
  PrintDocFlowOptions,
  PrinterType,
} from "./PrintDocFlowLike"
import { PDFRequestState, PrintDocFlowRequest } from "./PrintDocFlowRequest"
import { base64EncodeUtf8 } from "../../utilities/base64EncodeUtf8"

export interface ItemLabelData {
  title: string
  fnsku: string
  lpn?: string
  asin?: string
  category?: string
  brand?: string
  imageURL?: string
}

export interface PrintItemLabelRequestProps extends PrintDocFlowBaseOptions {
  itemLabelData: ItemLabelData
  children: PDFChildrenCallback
}

export class PrintItemLabelRequest
  extends React.PureComponent<PrintItemLabelRequestProps, PDFRequestState> {

  public constructor(props: PrintItemLabelRequestProps) {
    super(props)
  }

  private createOptions(): PrintDocFlowOptions {
    const { itemLabelData, printerConfiguration, ...rest } = this.props

    const labelArgs = {
      labels: {
        itemLabel: itemLabelData,
      }
    }

    // Convert to UTF-8 encoded base64 string
    const labelsJSON = JSON.stringify(labelArgs)
    const base64String = base64EncodeUtf8(labelsJSON)

    return {
      ...rest,
      documentType: DocumentType.ITEM_LABEL,
      printerConfiguration: {
        printerPort: printerConfiguration?.printerPort || 5964,
        printerType: printerConfiguration?.printerType || PrinterType.PRN_ZEBRA_THERMAL2,
        mediaType: printerConfiguration?.mediaType || MediaType.TWO_INCH,
      },
      metaData: {
        LABEL_ARGS: base64String,
      },
    }
  }

  public render() {
    return (
      <PrintDocFlowRequest {...this.createOptions()}>
        {(status, errorDetail) => {
          return this.props.children(status, errorDetail)
        }}
      </PrintDocFlowRequest>
    )
  }
}
