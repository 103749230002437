import { upperCase } from "./formatters/upperCase"
import { lowerCase } from "./formatters/lowerCase"
import { relativeTime } from "./formatters/relativeTime"
import { ProjectionType } from "../../config/ProjectionType"
import { binForView, binForVoice } from "./formatters/bin"
import MessageFormat, { CustomFormatter } from "@messageformat/core"
import { normalizeLocale } from "../helpers/normalizeLocale"

export interface MessageFormatOptions {
  projectionType?: ProjectionType
}

export function createMessageFormat(
  locale: string,
  {
    projectionType = "view",
  }: MessageFormatOptions = {}) {

  let customFormatters: {[key: string]: CustomFormatter} = {
    uppercase: upperCase,
    lowercase: lowerCase,
    relativetime: relativeTime,
  }

  // Breaks formats like "The total is {V, number, currency:{C}}" { V: 5.5, C: "EUR" }
  // but could be needed to render properly mixed LTR/RTL strings.
  // messageFormat.setBiDiSupport(true)
  if (projectionType === "view") {
    customFormatters = {
      ...customFormatters,
      bin: binForView,
    }
  } else if (projectionType === "voice") {
    customFormatters = {
      ...customFormatters,
      bin: binForVoice,
    }
  }

  const normalizedLocale = normalizeLocale(locale)
  const messageFormat = new MessageFormat(normalizedLocale, { customFormatters })

  return messageFormat
}
