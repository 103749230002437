import { ReactNode } from "react"
import { LocalizedString } from "../i18n/Translator"

/**
 * The options needed to print a document. These are the basic options that the user will provide
 * to the print components.
 */
export interface PrintDocFlowBaseOptions {
  /**
   * The printer name where the document will be printed
   */
  printerName?: string

  /**
   * The ID of the document to be printed.
   */
  documentID?: PrintDocumentFlowDocumentID

  /**
   * The configuration for the printer
   */
  printerConfiguration?: PrinterConfiguration

  /**
   * The request id to send to the printer
   */
  printRequestID?: string
}

/**
 * These options are also used to print a document. However, we don't want to expose it to the user.
 * With that said, our component might still get the data from the user but put the data to the
 * right place. For example, we set the documentType on behalf of our users by hardcoding it in the
 * component.
 */
export interface PrintDocFlowOptions extends PrintDocFlowBaseOptions {
  /**
   * The type of document to be printed
   */
  documentType: DocumentType

  /**
   * The meta data to be used for printing
   */
  metaData?: Record<string, string>
}

export interface PrintDocumentFlowDocumentID {
  /**
   * Type identifier type for the document
   */
  type: DocumentIDType

  /**
   * The document identifier
   */
  id: string
}

export interface PrinterConfiguration {
    deviceID?: string

    printerIP?: string

    printerPort?: number

    printerType?: PrinterType

    mediaType?: MediaType
}

export enum PDFRequestStatus {
  READY = "READY",
  // After a print request is sent, the status is updated to NOT_STARTED
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  ERRORED = "ERRORED",
  TIMED_OUT = "TIMED_OUT"
}

export enum DocumentIDType {
  SHIPMENT_ID = "SHIPMENT_ID",
  ORDERING_SHIPMENT_ID = "ORDERING_SHIPMENT_ID",
  PACKAGE_SCANNABLE_ID = "PACKAGE_SCANNABLE_ID",
  BATCH_ID = "BATCH_ID",
  SHIPMENT_ITEM_ID = "SHIPMENT_ITEM_ID",
  CN_INVOICE_ID = "CN_INVOICE_ID",
  RETURN_CONTRACT_ID = "RETURN_CONTRACT_ID",
  AMAZON_SHIPMENT_REFERENCE_ID = "AMAZON_SHIPMENT_REFERENCE_ID",
  CONTAINER_ID = "CONTAINER_ID",
  CONTAINER_SCANNABLE_ID = "CONTAINER_SCANNABLE_ID",
  PURCHASE_ORDER_ID = "PURCHASE_ORDER_ID",
}

export enum DocumentType {
  PSLIP = "PSLIP",
  GIFTTAG = "GIFTTAG",
  BATCH_COVER_SHEET = "BATCH_COVER_SHEET",
  CN_INVOICE = "CN_INVOICE",
  CNINVOICE = "CNINVOICE",
  RETURN_LABEL = "RETURN_LABEL",
  TAX_INVOICE = "TAX_INVOICE",
  SHIPPING_LABEL = "SHIPPING_LABEL",
  PROBLEM_SOLVE = "PROBLEM_SOLVE",
  COVID_MANIFEST = "COVID_MANIFEST",
  ITEM_LABEL = "ITEM_LABEL",
  BATCH_LABEL = "BATCH_LABEL",
  PURCHASE_ORDER_LABEL = "PURCHASE_ORDER_LABEL",
}

export enum PrinterType {
  PRN_EPSON_IMPACT = "PRN_EPSON_IMPACT",
  PRN_HP_LASER = "PRN_HP_LASER",
  PRN_PDF_VIRTUAL = "PRN_PDF_VIRTUAL",
  PRN_ZEBRA_THERMAL = "PRN_ZEBRA_THERMAL",
  PRN_ZEBRA_THERMAL2 = "PRN_ZEBRA_THERMAL2",
  PRN_IMAGE_PNG = "PRN_IMAGE_PNG",
  PRN_IMAGE_PNG_MULTIPAGE = "PRN_IMAGE_PNG_MULTIPAGE",
}

export enum MediaType {
  A4 = "A4",
  A5 = "A5",
  LETTER = "LETTER",
  TWO_INCH = "TWO_INCH",
  FOUR_INCH = "FOUR_INCH",
  SIX_INCH = "SIX_INCH",
  LEVIMATIC = "LEVIMATIC",
  COMBO = "COMBO",
  SCANNABLE_ID = "SCANNABLE_ID",
  FOURxSIX_INCH = "FOURxSIX_INCH",
}

// PDF requires the first 5 fields (https://tiny.amazon.com/ihwsrt76).
// However, like PAWS, customers don't use all of them.
// So when the field value is not provided, we'll auto set it to ""
export interface ProblemSolveExtraArgs {
  problemDescription?: string | LocalizedString
  problemInstructionsPre?: string | LocalizedString
  problemInstructionsPost?: string | LocalizedString
  shipmentExSDPre?: string | LocalizedString
  shipmentExSDPost?: string | LocalizedString
  shipmentId?: string
  containerScannableId?: string
}

/**
 * Print document flow interface
 */
export interface PrintDocFlowLike {
  readonly statusUpdateDelay: number
  readonly printTimeout: number

  submitPrint(requestID: string, options: PrintDocFlowOptions): Promise<PrintResponse>
  getStatus(requestID: string, options: PrintDocFlowBaseOptions): Promise<GetStatusResponse>
  print(
    options: PrintDocFlowOptions,
    callback: (printStatus: PDFRequestStatus, errorDetail?: PrintDocFlowError) => void
  ): Promise<void>
}

export type PDFChildrenCallback = (
  status: PDFRequestStatus,
  errorDetail?: PrintDocFlowError
) => ReactNode

export interface PrintDocFlowError {
  name: string // exception's name
  message?: string
  errorCode?: string
 }

export interface PrintResponse {
  success: boolean
  completedPrinting?: boolean,
  errorDetail?: PrintDocFlowError
}

export interface GetStatusResponse {
  status: PDFRequestStatus
  errorDetail?: PrintDocFlowError
}
