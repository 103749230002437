import React from "react"

import debounce from "lodash.debounce"

import { Button as BaseButton } from "@amzn/alchemy-components-react"

import { IconName } from "../icon/IconName"
import { LocaleContext, Locale} from "../i18n/LocaleContext"
import { HIGState } from "../../config/HIGState"
import { SoundManager } from "../../utilities/sound/SoundManager"
import { HotKey } from "../keyboard/HotKey"
import { HotKeyResponder, HotKeySet } from "../keyboard/HotKeyResponder"
import { getInferredHotKey } from "../keyboard/getInferredHotKey"
import { TestProps } from "../helpers/TestProps"
import { renderTestID } from "../helpers/renderTestID"
import { LocalizedString, Translator } from "../i18n/Translator"
import { IconNameMap } from "../icon/IconNameMap"
import { TranslatorContext } from "../i18n/TranslatorContext"

BaseButton.displayName = "AlchemyButton"

export type ButtonSize = "sm" | "md" | "lg" | "xl" | "2xl"

// The link variant is deprecated; please providing a link instead.
export type ButtonVariant =
  "primary" | "secondary" | "outline" | "ghost" | "plain" | "link"

export type ButtonVisualState =
  "default" | "hover" | "active" | "focused" | "disabled" | "clicked"

export type ButtonType = "button" | "reset" | "submit"

export type ButtonIconPosition = "before-text" | "after-text"

export interface ButtonProps extends TestProps {
  /**
   * Button variant.
   *
   * Default is "primary".
   */
  variant?: ButtonVariant

  /**
   * Button size.
   *
   * Default is "md".
   */
  size?: ButtonSize

  /**
   * Provide icon to display near button title.
   */
  iconName?: IconName

  /**
   * Where to display icon, before text or after?
   *
   * - before-text is "left" in LTR and "right" in RTL.
   * - after-text is "right" in LTR and "left" in RTL.
   *
   * Default is "before-text".
   */
  iconPosition?: ButtonIconPosition

  /**
   * Disable button.
   *
   * Disabled button can't be focused or clicked.
   */
  disabled?: boolean

  /**
   * Expand button to the whole available width.
   */
  fluid?: boolean

  /**
   * Make button to fit into parent container.
   */
  flex?: boolean

  /**
   * Force visual state for demo purposes. Not supported any more.
   */
  forceState?: ButtonVisualState

  /**
   * Disable click audio feedback.
   */
  mute?: boolean

  /**
   * Triggered when user clicks the button.
   */
  onClick?: () => void

  /**
   * Change type when button should have a special meaning in form.
   * If the button is to submit a form, the type should be "submit".
   *
   * Default is "button".
   */
  type?: ButtonType

  /**
   * Hot key or set of hot keys that triggers onClick.
   */
  hotKey?: HotKeySet

  /**
   * Aria label for the button icon
   */
  iconAriaLabel?: LocalizedString

  /**
   * The URL for the link. The button will be rendered as a[href=link].
   *
   * This supersedes the link variant
   */
  link?: string

  /**
   * Used with link.
   *
   * Use "noopener,noreferrer" to remove trace back to the current window.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/API/Window/open#windowfeatures.
   */
  rel?: string

  /**
   * Used with link.
   *
   * Use "_blank" to open the link in a new window.
   *
   * See https://developer.mozilla.org/en-US/docs/Web/API/Window/open#target.
   */
  target?: "_blank" | string
}

/**
 * Button.
 */
export class Button extends React.PureComponent<ButtonProps> {
  private readonly buttonRef = React.createRef<HTMLElement>()

  private readonly soundManager: SoundManager

  private readonly clickAudioFeedbackEnabled: boolean

  private readonly clickDebounceDelay = 100 // in ms

  private readonly defaultSize = "md"

  public constructor(props: ButtonProps) {
    super(props)

    this.handleClick = debounce(this.handleClick.bind(this),
                                this.clickDebounceDelay, { leading: true, trailing: false })

    this.handleHotKey = this.handleHotKey.bind(this)

    this.soundManager = HIGState.soundManager
    this.clickAudioFeedbackEnabled = HIGState.clickAudioFeedbackEnabled
  }

  public componentWillUnmount() {
    // Debounced methods should be cancelled to prevent setState() after destroy
    // @ts-ignore
    this.handleClick.cancel()
  }

  public handleClick() {
    // disable click processing if button is disabled
    if (this.props.disabled) {
      return
    }

    this.processClick()
  }

  public handleHotKey() {
    // explicitly mark hot key as not handled if button is disabled
    if (this.props.disabled) {
      return false
    }

    const button = this.buttonRef.current?.shadowRoot!.querySelector(".alchemy-button")

    // @ts-ignore
    button.click()
  }

  public processClick() {
    // audio feedback
    if (this.clickAudioFeedbackEnabled && !this.props.mute) {
      this.soundManager.play("confirmation")
    }

    // execute click
    const isFormButton = this.props.type === "submit" || this.props.type === "reset"

    // Alchemy can trigger submit/reset events on form buttons
    // so we only need to deal with normal button's onClick
    if (!isFormButton && this.props.onClick) {
      this.props.onClick()
    }
  }

  private renderWithAlchemy(translator: Translator, { direction }: Locale) {
    const size = this.props.size || this.defaultSize
    const variant = this.props.variant || "primary"

    const className = "button"
      + ` button--size-${size}`
      + ` button--variant-${variant}`
      + (this.props.flex ? ` button--flex` : "")
      + (this.props.fluid ? ` button--fluid` : "")

    const label = this.props.children && (
      <span className="button__label">
        {this.props.children}
        {this.props.hotKey && " "}
        {this.props.hotKey && <HotKey keyCode={this.props.hotKey} />}
      </span>
    )
    const hotKey = this.props.hotKey || getInferredHotKey(this.props.children)

    let link = this.props.link
    if (this.props.variant === "link" && !link) {
      link = "#"  // just to make it not empty to force Alchemy to render a link
    }

    let content = (
      <BaseButton
        className={className}

        variant={VariantMap[variant]}
        size={SizeMap[size]}
        icon={this.props.iconName && IconNameMap[this.props.iconName]}
        iconPosition={IconPositionMap[this.props.iconPosition || "before-text"]}
        iconAriaLabel={this.props.iconAriaLabel && translator.lookup(this.props.iconAriaLabel)}
        disabled={this.props.disabled}
        stretch={this.props.fluid}
        fill={this.props.flex}
        type={this.props.type}
        data-testid={renderTestID(this.props.testID)}

        accessKey={hotKey ? HotKey.describeHotKeySet(hotKey) : undefined}
        dir={direction}

        link={link}
        rel={link && this.props.rel}
        target={this.props.target}

        ref={this.buttonRef}
        onClick={this.handleClick}
      >
        {label}
      </BaseButton>
    )

    if (hotKey) {
      content = (
        <HotKeyResponder
          keyCode={hotKey}
          onKeyPress={this.handleHotKey}
        >
          {content}
        </HotKeyResponder>
      )
    }

    return content
  }

  public render() {
    return (
      <TranslatorContext.Consumer>
        {(translator) => (
          <LocaleContext.Consumer>
            {(locale) => {
              return this.renderWithAlchemy(translator, locale)
            }}
          </LocaleContext.Consumer>
        ) }
      </TranslatorContext.Consumer>
    )
  }
}

const VariantMap: Record<ButtonVariant, string> = {
  primary: "action",
  secondary: "base",
  outline: "base",
  ghost: "ghost",
  plain: "plain",
  link: "link"
}

// We are not using Alchemy's predinfed sizes, but using custom sizes
// for easy support of xl and 2xl.
const SizeMap: Record<ButtonSize,  string> = {
  "sm": "0.875",
  "md": "1",
  "lg": "1.25",
  "xl": "1.5",
  "2xl": "1.75"
}

const IconPositionMap: Record<ButtonIconPosition, string> = {
  "before-text": "start",
  "after-text": "end"
}
