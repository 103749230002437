import React from "react"

import { TextInput, TextInputSize, ValidationResult, TextInputVisualState } from "./TextInput"
import { LocalizedString } from "../i18n/Translator"
import { DangerousProps } from "../helpers/DangerousProps"
import { TestProps } from "../helpers/TestProps"

export interface QuantityInputProps extends DangerousProps, TestProps {
  /**
   * Form field name.
   */
  name?: string

  /**
   * Form field size.
   *
   * Affects font size, padding, margin etc.
   */
  size?: TextInputSize

  /**
   * Expand field to the whole available width.
   */
  fluid?: boolean

  /**
   * Make field to fit into parent container.
   */
  flex?: boolean

  /**
   * Width of the field.
   *
   * Grid units are only supported: 1gu..12gu (12gu is equal to fluid)
   */
  width?: string

  /**
   * Initial value for the field.
   */
  initialValue?: number | string

  /**
   * Enable to mark field as invalid if empty.
   */
  required?: boolean

  /**
   * Set min allowed value for the field.
   */
  minValue?: number

  /**
   * Set max allowed value for the field.
   *
   * IMPORTANT: Setting maxValue is strongly recommended as there have been a few
   * COEs due to improper handling of very large numbers.
   */
  maxValue?: number

  /**
   * Display placeholder.
   *
   * Not supported on IE7.
   */
  placeholder?: LocalizedString

  /**
   * Render field as read only.
   */
  readOnly?: boolean

  /**
   * Render field as disabled.
   *
   * Disabled fields are not submitted with form and can't be focused.
   */
  disabled?: boolean

  /**
   * Automatically focus field.
   */
  autoFocus?: boolean

  /**
   * Triggered when user changes the value and leaves the field.
   */
  onChange?: (newValue: number | undefined) => void

  /**
   * Force specific state for demo purposes.
   */
  forceState?: TextInputVisualState

  /**
   * Custom localized string to override the aria-label accessibility tag.
   *
   * This field is mandatory to make inputs accessible.
   */
  ariaLabel?: LocalizedString

  /**
   * String to override the aria-labelledby accessibility tag.
   *
   * This field will take precedence over ariaLabel.
   */
  ariaLabelledBy?: string

  /**
   * Unique id to map to the input and validation message.
   *
   */
  id?: string

  /**
   * Prevent barcode scans wrongfully being submitted as input. Implemented in response to
   * accidental barcode scan submitting large unexpected numbers.
   *
   * For the standard use case, it is recommended to not use barcodeSafe as it disables the use of
   * up and down arrows to change the input number. Instead, we recommend using minValue/maxValue
   * as safeguards against bad inputs from either the user or a barcode scan.
   *
   * If false, a regular HTML number input will be used. Barcode scans will be input with
   * non-digits stripped and can be submitted.
   * If true, an HTML text input will be used to track if non-digit characters are entered. Barcode
   * scans will be input without change. Inputs with non-digits will not pass the validator.
   *
   * @default false
   */
  barcodeSafe?: boolean
}

export interface QuantityInputStrings {
  minValue?: string,
  maxValue?: string,
  isNaN?: string,
}

export class QuantityInput extends React.PureComponent<QuantityInputProps> {
  private readonly textAlign = "center"

  private readonly filterRegExp = /\d/

  private readonly autoSelect = true

  public static defaultStrings?: QuantityInputStrings

  private readonly emptyStrings: QuantityInputStrings = {}

  public constructor(props: QuantityInputProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.validator = this.validator.bind(this)
  }

  public handleChange(newValue: string) {
    if (this.props.onChange) {
      const newNumValue = parseInt(newValue, 10)
      this.props.onChange(isNaN(newNumValue) ? undefined : newNumValue)
    }
  }

  public validator(value: string): ValidationResult {
    const strings = QuantityInput.defaultStrings || this.emptyStrings

    // mark as valid, let required checker to determine validity if it is blank
    if (value === "") {
      return {
        valid: true,
      }
    }

    const digitFilter = new RegExp(`^(${this.filterRegExp.source})+$`)
    if (!digitFilter.test(value)) {
      return {
        valid: false,
        failureMessage: {
          stringID: strings.isNaN,
          defaultString: "Please enter a number."
        }
      }
    }

    const numValue = parseInt(value, 10)

    // invalid numeric value makes field invalid
    if (isNaN(numValue)) {
      return {
        valid: false,
        failureMessage: {
          stringID: strings.isNaN,
          defaultString: "Please enter a number."
        }
      }
    }

    // check for minValue restriction
    if (this.props.minValue !== undefined
      && this.props.minValue !== null // could be passed as alternative to undefined
      && numValue < this.props.minValue
    ) {
      return {
        valid: false,
        failureMessage: {
          parameters: {value: this.props.minValue },
          stringID: strings.minValue,
          defaultString: "Minimum value: {value}"
        }
      }
    }

    // check for maxValue restriction
    if (this.props.maxValue !== undefined
      && this.props.maxValue !== null // could be passed as alternative to undefined
      && numValue > this.props.maxValue
    ) {
      return {
        valid: false,
        failureMessage: {
          parameters: {value: this.props.maxValue },
          stringID: strings.maxValue,
          defaultString: "Maximum value: {value}"
        }
      }
    }

    return { valid: true }
  }

  public render() {
    const { initialValue } = this.props

    // we don't touch invalid string value here but field won't pass validation with invalid value
    const safeInitialValue = initialValue !== null && initialValue !== undefined
                           ? `${initialValue}`
                           : undefined
    const strings = QuantityInput.defaultStrings

    return (
      <TextInput
        id={this.props.id}
        // If barcode is disabled, allow entering of other characters
        // to enable checking if non-digits were entered
        filter={this.props.barcodeSafe ? undefined : this.filterRegExp}
        filterFailureMessage={{ stringID: strings ? strings.isNaN : undefined, defaultString: "Please enter a number."}}
        name={this.props.name}
        size={this.props.size}
        fluid={this.props.fluid}
        flex={this.props.flex}
        width={this.props.width}
        initialValue={safeInitialValue}
        validator={this.validator}
        required={this.props.required}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        textAlign={this.textAlign}
        autoFocus={this.props.autoFocus}
        autoSelect={this.autoSelect}
        onChange={this.handleChange}
        dangerousClassName={this.props.dangerousClassName}
        dangerousStyle={this.props.dangerousStyle}
        forceState={this.props.forceState}
        // If barcode is disabled, use html text input to allow entering
        // of non-digits to check if any were entered
        contentType={this.props.barcodeSafe ? undefined : "number"}
        testID={this.props.testID}
        ariaLabel={this.props.ariaLabel}
        ariaLabelledBy={this.props.ariaLabelledBy}
        min={this.props.minValue}
        max={this.props.maxValue}
      />
    )
  }
}
