import React from "react"

import { ChromeConfirmationModal } from "./ChromeConfirmationModal"
import { HotKeyCode } from "../keyboard/HotKeyCode"

type ChromeVoiceAckModalStringKey =
  "caption" | "body1" | "body2" | "acknowledge" | "reject"

export type ChromeVoiceAckModalStrings = Partial<Record<ChromeVoiceAckModalStringKey, string>>

interface ChromePresenceModalProps {
  /**
   * Callback executed when user manually acknowledges speech use.
   */
  onReject?: () => void

  /**
   * Callback executed when user manually refuses speech use.
   */
  onAcknowledge?: () => void
}

/**
 * Acknowledgement modal that is displayed to user when they first log in and speech is enabled.
 */
export class ChromeVoiceAckModal extends React.PureComponent<ChromePresenceModalProps> {
  // override this value to customize strings and provide translations
  public static defaultStrings: ChromeVoiceAckModalStrings = {}

  public static readonly defaultTexts: Record<ChromeVoiceAckModalStringKey, string> = {
    caption: "Use of Voice",
    body1: "While in use, PAWS Grocery Voice Picking uses a microphone to record your voice inputs. Sign out of the app to stop voice recording and when not performing picking functions.",
    body2: "PAWS Grocery Voice Picking uses software provided by Amazon Web Services, Inc. to transcribe your speech into text outputs, allowing you to interact with the app using your voice. Data derived from your speech to create text outputs may be considered biometric data in some jurisdictions. Recordings of your speech and data derived from those recordings to create text transcripts will be deleted after transcribing. Text transcripts of your speech will be retained for up to 90 days. By clicking \"Continue\" below, you acknowledge and agree that you have read this disclosure, and you provide voluntary, informed consent to the collection, use, and storage of your speech recordings, data derived from those recordings, and text transcripts of your speech recordings, now and in the future.",
    acknowledge: "Continue",
    reject: "Sign out",
  }

  public static readonly hotKeys: Record<"acknowledge" | "reject", HotKeyCode> = {
    acknowledge: "Enter",
    reject: "KeyS",
  }

  public render() {
    const { defaultTexts, defaultStrings, hotKeys } = ChromeVoiceAckModal

    return (
      <ChromeConfirmationModal
        id="voiceAcknowledgement"

        caption={{
          stringID: defaultStrings.caption,
          defaultString: defaultTexts.caption,
        }}

        body={{
          stringID: defaultStrings.body1,
          defaultString: defaultTexts.body1,
        }}

        secondaryBody={{
          stringID: defaultStrings.body2,
          defaultString: defaultTexts.body2,
        }}

        primaryButtonHotKey={hotKeys.acknowledge}
        primaryButtonLabel={{
          stringID: defaultStrings.acknowledge,
          defaultString: defaultTexts.acknowledge,
        }}
        onPrimaryButtonClick={this.props.onAcknowledge}

        secondaryButtonHotKey={hotKeys.reject}
        secondaryButtonLabel={{
          stringID: defaultStrings.reject,
          defaultString: defaultTexts.reject,
        }}
        onSecondaryButtonClick={this.props.onReject}
        disablePrimaryButtonUntilScrolledToEnd={true}
      />
    )
  }
}
