import React from "react"
import { ThemeContext, Theme } from "../../theme/ThemeContext"
import { LocaleContext } from "../../i18n/LocaleContext"
import { LocalizedString } from "../../i18n/Translator"
import { TestProps } from "../../helpers/TestProps"
import { renderTestID } from "../../helpers/renderTestID"
import { Button } from "../../../components/button/Button"

interface DrawerContextType {
  isOpen: boolean
  openDrawer: () => void
  closeDrawer: () => void
}

const DrawerContext = React.createContext<DrawerContextType>({
  isOpen: false,
  openDrawer: () => {},
  closeDrawer: () => {},
})

export interface DrawerLayoutMainProps extends TestProps {
  openButtonLabel?: LocalizedString
  children?: React.ReactNode
}

export interface DrawerLayoutAsideProps extends TestProps {
  initialOpen?: boolean
  label?: LocalizedString
  closeButtonLabel?: LocalizedString
  children?: React.ReactNode
}

export interface DrawerLayoutProps extends TestProps {
  children?: React.ReactNode
}

interface DrawerLayoutState {
  isOpen: boolean
}

export class DrawerLayoutMain extends React.Component<DrawerLayoutMainProps> {
  public static contextType = DrawerContext

  public render() {
    return (
      <DrawerContext.Consumer>
        {({ openDrawer }) => (
          <div
            className="drawer-main"
            data-testid={renderTestID(this.props.testID)}
          >
            <div className="main-content">{this.props.children}</div>
            <div className="button-container">
              <Button
                onClick={openDrawer}
                aria-label={this.props.openButtonLabel?.toString()}
                testID={`${this.props.testID}-open-button`}
              >
                {this.props.openButtonLabel?.toString()}
              </Button>
            </div>
          </div>
        )}
      </DrawerContext.Consumer>
    )
  }
}

export class DrawerLayoutAside extends React.Component<DrawerLayoutAsideProps> {
  public static contextType = DrawerContext

  public render() {
    return (
      <DrawerContext.Consumer>
        {({ isOpen, closeDrawer }) => (
          <div
            className="drawer-aside"
            data-testid={renderTestID(this.props.testID)}
            aria-hidden={!isOpen}
          >
            <div className="header">
              {this.props.label && (
                <div className="header-label">{this.props.label}</div>
              )}
            </div>
            <div className="aside-content">{this.props.children}</div>
            <div className="footer">
              <Button
                onClick={closeDrawer}
                aria-label={this.props.closeButtonLabel?.toString()}
                testID={`${this.props.testID}-close-button`}
              >
                {this.props.closeButtonLabel?.toString()}
              </Button>
            </div>
          </div>
        )}
      </DrawerContext.Consumer>
    )
  }
}

export class DrawerLayout extends React.Component<
  DrawerLayoutProps,
  DrawerLayoutState
> {
  public static Main = DrawerLayoutMain
  public static Aside = DrawerLayoutAside

  public state: DrawerLayoutState = {
    isOpen: this.getInitialDrawerState(),
  }

  private getInitialDrawerState(): boolean {
    const children = React.Children.toArray(this.props.children)
    const asideChild = children.find(
      (child) =>
        React.isValidElement(child) && child.type === DrawerLayout.Aside
    )
    return React.isValidElement(asideChild) && asideChild.props.open === true
  }

  public openDrawer() {
    this.setState({ isOpen: true })
  }

  public closeDrawer() {
    this.setState({ isOpen: false })
  }

  public render() {
    return (
      <DrawerContext.Provider
        value={{
          isOpen: this.state.isOpen,
          openDrawer: this.openDrawer,
          closeDrawer: this.closeDrawer,
        }}
      >
        <div
          className={`drawer-layout ${this.state.isOpen ? "drawer-open" : ""}`}
          data-testid={renderTestID(this.props.testID)}
        >
          {this.props.children}
        </div>
      </DrawerContext.Provider>
    )
  }
}
