import React from "react"

import { LocalizedString } from "../i18n/Translator"
import { FlexLayout } from "../layout/flex/FlexLayout"
import { LocalizedText } from "../text/LocalizedText"
import { ViewportCapsContext } from "../layout/viewport/ViewportCapsContext"
import { HotKeyCode } from "../keyboard/HotKeyCode"
import { PlaySound } from "../sound/PlaySound"
import { ConfirmationFrame } from "../layout/frames/ConfirmationFrame"
import { ScrollableLayout } from "../layout/scrollable/ScrollableLayout"

interface ChromeConfirmationModalProps {
  /**
   * Unique ID of modal.
   *
   * Required to properly skip play sound on rerenders.
   */
  id: string

  /**
   * Caption that is displayed in the top of modal.
   */
  caption?: LocalizedString

  /**
   * Modal body, supports attributed text.
   */
  body?: LocalizedString

  /**
   * Additional body text to explain absence of sign out button.
   */
  secondaryBody?: LocalizedString

  /**
   * Primary button label.
   */
  primaryButtonLabel?: LocalizedString

  /**
   * Primary button hot key.
   */
  primaryButtonHotKey?: HotKeyCode

  /**
   * Secondary button label.
   */
  secondaryButtonLabel?: LocalizedString

  /**
   * Secondary button hot key.
   */
  secondaryButtonHotKey?: HotKeyCode

  /**
   * Primary button click callback.
   */
  onPrimaryButtonClick?: () => void

  /**
   * Secondary button click callback.
   */
  onSecondaryButtonClick?: () => void

  /**
   * When true, primary button remains disabled until content is scrolled to end.
   * When false or undefined, primary button is always enabled regardless of scroll position.
   */
  disablePrimaryButtonUntilScrolledToEnd?: boolean
}

interface ChromeConfirmationModalState {
  hasScrolledToEnd: boolean
}

/**
 * Chrome confirmation modal with semi-transparent outline.
 *
 * Designed to be passed as `modal` parameter of chrome.
 */
export class ChromeConfirmationModal extends
  React.PureComponent<ChromeConfirmationModalProps, ChromeConfirmationModalState> {
  constructor(props: ChromeConfirmationModalProps) {
    super(props)
    this.state = {
      hasScrolledToEnd: false,
    }
    this.handleScrollToEnd = this.handleScrollToEnd.bind(this)
  }

  public reminderInterval = 30 // in secs / value is approved by bartolm@

  private handleScrollToEnd() {
    // Once scrolled to the end once, primary button will be enabled
    if (!this.state.hasScrolledToEnd) {
      this.setState({ hasScrolledToEnd: true })
    }
  }

  public render() {
    const {
      id,
      caption,
      body,
      secondaryBody,
      primaryButtonLabel,
      primaryButtonHotKey,
      secondaryButtonLabel,
      secondaryButtonHotKey,
      onPrimaryButtonClick,
      onSecondaryButtonClick,
      disablePrimaryButtonUntilScrolledToEnd,
    } = this.props

    const { hasScrolledToEnd } = this.state

    const isPrimaryButtonDisabled =
      disablePrimaryButtonUntilScrolledToEnd && !hasScrolledToEnd

    return (
      <ViewportCapsContext.Consumer>
        {(viewportCaps) => {
          // a ~little~ bit of responsiveness to make layout better on desktop platform
          const smallAtMost = viewportCaps.hSizeAtMost("sm")
          const buttonPanelSize = smallAtMost ? "5vu" : "3vu"
          const captionFontSize = smallAtMost ? "xl" : "3xl"
          const bodyFontSize = smallAtMost ? "md" : "xl"

          return (
            <ConfirmationFrame
              primaryButtonLabel={primaryButtonLabel}
              primaryButtonHotKey={primaryButtonHotKey}
              secondaryButtonLabel={secondaryButtonLabel}
              secondaryButtonHotKey={secondaryButtonHotKey}
              onPrimaryButtonClick={onPrimaryButtonClick}
              onSecondaryButtonClick={onSecondaryButtonClick}
              primaryButtonDisabled={isPrimaryButtonDisabled}
            >

              <PlaySound id={id} sound="attention" reminder={this.reminderInterval}>
                <FlexLayout direction="column">
                  <FlexLayout.Item
                    padding="sm"
                  >
                    <FlexLayout
                      direction="column"
                      padding="md"
                      paddingBottom="none"
                      backgroundColor="dark-blue"
                      rounded={true}
                    >
                      {caption && (
                        <FlexLayout.Item size="auto">
                          <LocalizedText
                            variant="white"
                            size={captionFontSize}
                            bold={true}
                            {...caption}
                          />
                        </FlexLayout.Item>
                      )}

                      {body && (
                        <FlexLayout.Item>
                          <ScrollableLayout
                            onScrollToEnd={this.handleScrollToEnd}
                          >
                            <p>
                              <LocalizedText
                                attributed={true}
                                variant="white"
                                size={bodyFontSize}
                                {...body}
                              />
                            </p>
                            {secondaryBody && (
                              <p>
                                <LocalizedText
                                  attributed={true}
                                  variant="white"
                                  size={bodyFontSize}
                                  {...secondaryBody}
                                />
                              </p>
                            )}
                          </ScrollableLayout>
                        </FlexLayout.Item>
                      )}
                    </FlexLayout>
                  </FlexLayout.Item>
                </FlexLayout>
              </PlaySound>

            </ConfirmationFrame>
          )
        }}
      </ViewportCapsContext.Consumer>
    )
  }
}
