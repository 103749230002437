import {
  GetStatusResponse,
  PrintDocFlowLike,
  PrintDocFlowBaseOptions,
  PrintResponse,
  PDFRequestStatus,
  PrintDocFlowOptions,
  PrintDocFlowError
} from "./PrintDocFlowLike"

export type PrintDocFlowStubStatus = "good" | "bad"

/**
 * PrintDocumentFlow stub implementation to test successful and failed print requests
 * without real printer in test environments and locally.
 */
export class PrintDocFlowStub implements PrintDocFlowLike {
  // These values don't matter as all the data is fake
  private readonly targetServer = ""
  public readonly printTimeout: number = 100000 // ms
  public readonly statusUpdateDelay: number = 5000

  // Update status
  private readonly status: PrintDocFlowStubStatus

  // This is used to pretend progress is being made
  private updateProgress = -1

  public constructor(status: PrintDocFlowStubStatus, printTimeout?: number) {
    this.status = status
    if (printTimeout) {
      this.printTimeout = printTimeout
    }
  }

  public async submitPrint(
    requestID: string,
    PDFOptions: PrintDocFlowOptions
  ): Promise<PrintResponse> {
    console.log("PrintDocumentFlow.print()", PDFOptions)
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success: this.status === "good",
          completedPrinting: false,
          errorDetail: this.status === "good" ?
            undefined :
            {
              name: "PrintDocumentFlowException"
            }
        })
      }, 1000)
    })
  }

  public async getStatus(
      id: string,
      PDFOptions: PrintDocFlowBaseOptions
    ): Promise<GetStatusResponse> {

    const statusMap = [
      PDFRequestStatus.NOT_STARTED,
      PDFRequestStatus.IN_PROGRESS,
      this.status === "good" ? PDFRequestStatus.COMPLETED : PDFRequestStatus.ERRORED
    ]

    console.log("PrintDocumentFlow.getStatus()", id, PDFOptions)
    return new Promise((resolve) => {
      setTimeout(() => {
        // Test ID is always "stub"
        if (id !== "stub") {
          return resolve({
            status: PDFRequestStatus.ERRORED,
            errorDetail: {
              name: "PrintDocumentFlowException"
            }
          })
        }

        this.updateProgress++
        return resolve({
          status: statusMap[this.updateProgress]
        })
      }, 1000)
    })
  }

  public async print(
    options: PrintDocFlowOptions,
    callback: (printStatus: PDFRequestStatus, errorDetail?: PrintDocFlowError) => void
  ) {
    /**
     * This is used to time out the request.
     */
    const startTimestamp = Date.now()

    if (!options.printRequestID) {
      // Object is not extensible so we do this
      options = {
        ...options,
        printRequestID: "stub"
      }
    }

    /**
     * Make the initial request to the API which will
     * forward the request to the Print Document Flow
     * service.
     */
    let printRes: PrintResponse
    try {
      printRes = await this.submitPrint(options.printRequestID!, options)
    } catch (e) {
      return callback(PDFRequestStatus.ERRORED)
    }

    if (!printRes.success) {
      return callback(PDFRequestStatus.ERRORED, printRes.errorDetail)
    }

      // Starts the polling
    return await this.startPolling(options.printRequestID!, options, startTimestamp, callback)
  }

  /**
   * Starts polling the api for status updates
   * @param startTimestamp The timestamp of when the first request was sent
   */
  private async startPolling(
    jobId: string,
    options: PrintDocFlowBaseOptions,
    startTimestamp: number,
    callback: (printStatus: PDFRequestStatus, errorDetail?: PrintDocFlowError) => void
    ) {
    let currentStatus = PDFRequestStatus.NOT_STARTED

    const poll = async () => {
      if (!this.shouldStillFetchUpdate(currentStatus)) {
        return
      }

      /**
       * Check for timeout. This will most likely fire after the timeout delay.
       * Worst case scenario: printTimeout + statusUpdateDelay + getStatus (res delay)
       */
      if (Date.now() - startTimestamp >= this.printTimeout) {
        return callback(PDFRequestStatus.TIMED_OUT)
      }

      /**
       * Gets the current status of the print
       */
      let statusRes: GetStatusResponse
      try {
        statusRes = await this.getStatus(jobId, options)
      } catch (e) {
        return callback(PDFRequestStatus.ERRORED)
      }

      /**
       * Update the component if the print status has not timed out
       */
      if (statusRes.status !== currentStatus) {
        currentStatus = statusRes.status
        callback(statusRes.status, statusRes.errorDetail)
      }

      setTimeout(() => poll(), this.statusUpdateDelay)
    }

    // Start polling and reset counter
    this.updateProgress = -1
    poll()
  }

  /**
   * Checks if the component should still attempt to update
   */
  public shouldStillFetchUpdate(status: PDFRequestStatus) {
    return (
      status !== PDFRequestStatus.COMPLETED &&
      status !== PDFRequestStatus.ERRORED &&
      status !== PDFRequestStatus.TIMED_OUT
    )
  }
}
