import React from "react"

import { renderTestID } from "../helpers/renderTestID"
import { TestProps } from "../helpers/TestProps"
import { LocalizedString, Translator } from "../i18n/Translator"
import { withTranslator } from "../i18n/withTranslator"
import { Icon } from "../icon/Icon"
import { TextLayout } from "../layout/text/TextLayout"
import { getProductImageServer } from "./getProductImageServer"

export interface ProductImageProps extends TestProps {
  /**
   * Note: the url will be rewritten with additional parameters and a different host
   * depending on the network.
   */
  url: string
  hidden?: boolean
  noCache?: boolean
  translator: Translator
  altText?: LocalizedString
}

export interface ProductImageState {
  status: "loading" | "loaded" | "failed"
}

class ProductImageBase extends React.PureComponent<ProductImageProps, ProductImageState> {
  public static fabric: "prod" | "corp" = "corp"

  public rootRef = React.createRef<HTMLDivElement>()

  public constructor(props: ProductImageProps) {
    super(props)

    this.state = {
      status: props.url ? "loading" : "failed",
    }

    this.handleImageError = this.handleImageError.bind(this)
    this.handleImageLoad = this.handleImageLoad.bind(this)
  }

  public componentDidMount() {
    // redraw component using container size once we know it (after initial render)
    if (this.rootRef.current) {
      this.forceUpdate()
    }
  }

  public handleImageError() {
    this.setState({ status: "failed" })
  }

  public handleImageLoad() {
    this.setState({ status: "loaded" })
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ProductImageProps) {
    // invalidate status if image url has changed
    if (nextProps.url !== this.props.url && this.state.status !== "loading") {
      this.setState({ status: "loading" })
    }
  }

  // move out into separate method to easily mock
  public getElementSize(element: HTMLDivElement | null | undefined) {
    if (element) {
      // getBoundingClientRect() returns size after applying transformations
      // so we can get real size in pixels even within ZoomLayout, so we can
      // request right resolution and render crisp image.
      const { width, height } = element.getBoundingClientRect()
      return { width, height }
    }
  }

  public getImageURL() {
    if (!this.props.url) {
      return
    }

    const size = this.getElementSize(this.rootRef.current)

    if (size) {
      const { width, height } = size

      // modern browsers report device pixel ratio
      const devicePixelRatio = window.devicePixelRatio || 1
      const imageWidth = Math.round(width * devicePixelRatio)
      const imageHeight = Math.round(height * devicePixelRatio)

      // AC - white space auto crop, SX - scale to width, SY - scale to height
      // see more: https://w.amazon.com/index.php/MSA/HowTo/ImageStyleCodes
      // Assume that image is square so request size to fit into smallest side
      const imageOptions = imageWidth < imageHeight
        ? `_AC_SX${imageWidth}_`
        : `_AC_SY${imageHeight}_`

      return this.props.url
        // replace Public CDN with Corp CDN
        .replace(/^(https?:\/\/)[^/]+/, getProductImageServer(ProductImageBase.fabric))
        // inject MSA tags into filename
        .replace(/(\.[^.]+)$/, "." + imageOptions + "$1")
        // disable caching if needed
        + (this.props.noCache ? `?ts=${Date.now()}` : "")
    }
  }

  public render() {
    const { altText, translator, testID } = this.props
    const className = "product-image"
      + ` product-image--${this.state.status}`
      + (this.props.hidden ? " product-image--hidden" : "")

    const imageURL = this.getImageURL()
    const hasImageURL = !!imageURL

    return (
      <div
        className={className}
        ref={this.rootRef}
        data-testid={renderTestID(testID)}
      >
        {hasImageURL && this.state.status !== "failed" && (
          <>
            <img
              src={imageURL}
              className="product-image__img"
              onError={this.handleImageError}
              onLoad={this.handleImageLoad}
              alt={altText ? translator.lookup(altText) : ""}
            />
            {/* Code to test migration off Internal CDN */}
            <img
              src="https://m.media-amazon.com/images/S/media-delivery-rouleur-test/accept._FC_.png"
              id="test"
              className="product-image--hidden"
            />
          </>
        )}
        {this.state.status === "failed" && (
          <TextLayout hAlign="center" vAlign="middle">
            <Icon name="image" size={32} color="light-gray" />
          </TextLayout>
        )}
      </div>
    )
  }
}

export const ProductImage = Object.assign(
  withTranslator(ProductImageBase), {
    displayName: "ProductImage",
    fabric: ProductImageBase.fabric,
    setFabric(fabric: "prod" | "corp") {
      ProductImageBase.fabric = fabric
    },
    elementSize: ProductImageBase.prototype.getElementSize,
    setElementSize(
      elementSize: (element: HTMLDivElement | null | undefined) =>
      { width: number, height: number} | undefined
    ) {
      ProductImageBase.prototype.getElementSize = elementSize
    }
  }
)
