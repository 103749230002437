import React from "react"

import { LocalizedString } from "../i18n/Translator"
import { Alert } from "../alert/Alert"
import { TestProps } from "../helpers/TestProps"
import { PlaySound } from "../sound/PlaySound"
import { SoundName } from "../../utilities/sound/SoundName"

/**
 * Type of Notification, same as Alert types but including "default" which results in "info".
 */
export type NotificationType = "success" | "error" | "warning" | "info" | "default"

const notificationTypeToSoundNameMap: Record<NotificationType, SoundName> = {
  success: "success",
  error: "error",
  warning: "attention",
  default: "confirmation",
  info: "confirmation",
}

/**
 * Notification to be shown with tasks.
 */
export interface Notification {
  /**
   * The type of the notification.
   *
   * Defaults to info.
   */
  type?: NotificationType
  message: LocalizedString

  /**
   * If true, plays sound when notification is shown.
   *
   * Default to true
   */
  withSound?: boolean
}

interface ChromeNotificationBarProps  extends TestProps {
  /**
   * Notification to be shown
   */
  notification: Notification
}

/**
 * Display notification bar to the user.
 *
 * Designed to display task Notification messages but can be used for other messages.
 */
export class ChromeNotificationBar extends React.PureComponent<ChromeNotificationBarProps> {

  public constructor(props: ChromeNotificationBarProps) {
    super(props)
  }

  public render() {
    const notification = this.props.notification
    const notificationType = notification.type ?
      (notification.type === "default" ? "info" : notification.type)
      : "info"

    return (
      <PlaySound
        sound={notificationTypeToSoundNameMap[notificationType]}
        mute={!(this.props.notification.withSound ?? true)}
      >
        <Alert
          variant={notificationType}
          title={notification.message}
          density="dense"
          testID={this.props.testID}
        />
      </PlaySound>
    )
  }
}
