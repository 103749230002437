import React from "react"

import { sticky, isNativeStickySupported } from "../../../utilities/sticky"
import { DangerousProps } from "../../helpers/DangerousProps"
import { LocalizedString, Translator } from "../../i18n/Translator"
import { withTranslator } from "../../i18n/withTranslator"

export interface ScrollableLayoutProps extends DangerousProps {
  stickyHeaders?: boolean
  // @deprecated
  scrollStep?: number // not used on modern web platform
  onScroll?: (scrollTop: number) => void
  ariaRole?: string
  tabIndex?: number
  onFocus?: (el: HTMLDivElement) => void
  onBlur?: () => void
  ariaLabel?: LocalizedString
  translator: Translator
  onScrollToEnd?: () => void
}

/**
 * ScrollableLayout that is using system vertical scrollbar and can report
 * scroll position.
 *
 * Note: ScrollableLayout must be in a parent with definite height.
 * FlexLayout.Item with size in fr can also work.
 */
export class ScrollableLayoutBase extends React.PureComponent<ScrollableLayoutProps> {
  public static nativeSticky = isNativeStickySupported()

  private readonly rootRef = React.createRef<HTMLDivElement>()

  private removeSticky?: () => void

  public constructor(props: ScrollableLayoutProps) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  public handleFocus() {
    if (this.props.onFocus) {
      if (this.rootRef.current) {
        this.props.onFocus(this.rootRef.current)
      }
    }
  }

  public handleBlur(event: React.FocusEvent) {
    if (this.props.onBlur) {
      // If parent element does not contain focused element, set navbar to inactive
      if (!event.currentTarget.contains(event.relatedTarget as Node)) {
        this.props.onBlur()
      }
    }
  }

  public componentDidMount() {
    if (this.props.stickyHeaders
      && this.rootRef.current
      && !ScrollableLayoutBase.nativeSticky
    ) {
      this.removeSticky = sticky(this.rootRef.current)
    }

    this.checkScrollPosition()
  }

  public componentWillUnmount() {
    if (this.removeSticky) {
      this.removeSticky()
    }
  }

  public handleScroll(event: React.SyntheticEvent) {
    if (this.props.onScroll) {
      const target = event.target as HTMLDivElement
      this.props.onScroll(target.scrollTop)
    }

    this.checkScrollPosition()
  }

  private checkScrollPosition() {
    if (!this.props.onScrollToEnd) {
      return
    }

    if (this.rootRef.current) {
      const element = this.rootRef.current
      const isAtEnd = element.scrollHeight - element.clientHeight - element.scrollTop < 1

      if (isAtEnd) {
        this.props.onScrollToEnd()
      }
    }
  }

  public render() {
    const className = "scrollable-layout"
      + (this.props.dangerousClassName ? ` ${this.props.dangerousClassName}` : "")

    return (
      <div
        className={className}
        style={this.props.dangerousStyle}
        onScroll={this.handleScroll}
        ref={this.rootRef}
        role={this.props.ariaRole}
        tabIndex={this.props.tabIndex}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        aria-label={
          this.props.ariaLabel ? this.props.translator.lookup(this.props.ariaLabel) : undefined
        }
      >
        {this.props.children}
      </div>
    )
  }
}

export const ScrollableLayout = Object.assign(
  withTranslator(ScrollableLayoutBase), {
    displayName: "ScrollableLayout",
    nativeSticky: ScrollableLayoutBase.nativeSticky
  }
)
