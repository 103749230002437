/**
 * Function to encode a UTF-8 string into a base64 string
 */
export function base64EncodeUtf8(str: string) {
  const utf8Bytes = new TextEncoder().encode(str)
  const binary = Array.from(utf8Bytes)
    .map( (byte) => String.fromCharCode(byte))
    .join("")
  return btoa(binary)
}
