import { CustomFormatter } from "@messageformat/core"
import { parseFormatterArg } from "./parseFormatterArg"

// tslint:disable: max-line-length

// Intl.RelativeTimeFormat constructor is expensive so cache instances here
// @ts-ignore Type is not available in TypeScript 3.6.x
const rtfCache: { [key: string]: Intl.RelativeTimeFormat } = {}

type RelativeTimeFormatUnit =
  | "year" | "years"
  | "quarter" | "quarters"
  | "month" | "months"
  | "week" | "weeks"
  | "day" | "days"
  | "hour" | "hours"
  | "minute" | "minutes"
  | "second" | "seconds"

/**
 * Render numeric `value` using `locale` with parameters specified in `arg`.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RelativeTimeFormat
 *
 * @param value  Numeric value to use in the internationalized relative time message.
 * @param locale ICU locale.
 * @param arg    Format "{unit}:{style}:{numeric}". Parameters separated by ":".
 *               Unit is mandatory, style and numeric are optional.
 *               Unit: "year", "quarter", "month", "week", "day", "hour",
 *                     "minute", "second". Plural forms are also permitted.
 *               Style: "long", "short", "narrow".
 *               Numeric: "always", "auto".
 */
export const relativeTime: CustomFormatter = (value, locale, arg) => {
  const args: string[] = parseFormatterArg(arg)
  const unit = args[0] as RelativeTimeFormatUnit                // mandatory
  const style = args[1] || "long"     // optional, default value as per specs
  const numeric = args[2] || "always" // optional, default value as per specs
  const hash = `${locale}:${style}:${numeric}`
  const rtf = rtfCache[hash]
            ? rtfCache[hash]
            // @ts-ignore Type is not available in TypeScript 3.6.x
            : new Intl.RelativeTimeFormat(locale, { style, numeric })
  // @ts-ignore type of value is set as unknown for CustomFormatter
  return rtf.format(value, unit)
}
