import React from "react"

import { BaseProductBox, BaseProductBoxProps } from "./BaseProductBox"

import { FlexLayout } from "../../layout/flex/FlexLayout"

export interface TwoColumnProductBoxProps extends BaseProductBoxProps {
  hideImage?: boolean
  /**
   * "auto" will align the image to the top, instead of to the middle. Otherwise,
   * the image might be hidden if the text is too long.
   */
  heightMode?: "fit" | "auto"
}

export class TwoColumnProductBox extends BaseProductBox<TwoColumnProductBoxProps> {
  private renderWithImage() {
    const packOfN = this.renderPackOfN()
    const nonImageComponents = [
      this.renderProductIdAndQuantity("md"),
      this.renderProductBinding(),
      this.renderProductTitle(),
      this.renderProductBrand()
    ].filter((v) => !!v) as JSX.Element[]

    const dangerousClassName =
      this.props.heightMode === "auto" ? "flex-layout--height-auto" : undefined

    const mainPart = (
      <FlexLayout
        direction="row"
        dangerousClassName={dangerousClassName}
      >
        <FlexLayout.Item size="1fr" paddingVertical="xs">
          <FlexLayout direction="row" vAlign={this.props.heightMode === "fit" ? "middle" : "top"}>
            {this.renderProductImage("1fr")}
          </FlexLayout>
        </FlexLayout.Item>
        <FlexLayout.Item key="descriptions" size="2fr" padding="xs">
          <FlexLayout direction="column">
            {this.interweaveComponentsWithSpacer(nonImageComponents)}
          </FlexLayout>
        </FlexLayout.Item>
      </FlexLayout>
    )

    return (
      <FlexLayout
        direction="column"
        testID={this.props.testID}
        dangerousClassName={dangerousClassName}
      >
        {packOfN}
        <FlexLayout.Item>
          {mainPart}
        </FlexLayout.Item>
      </FlexLayout>
    )
  }

  private renderWithouImage() {
    const components = [
      this.renderPackOfN(),
      this.renderProductIdAndQuantity("md"),
      this.renderProductBinding(),
      this.renderProductTitle(),
      this.renderProductBrand()
    ].filter((v) => !!v) as JSX.Element[]

    const dangerousClassName =
      this.props.heightMode === "auto" ? "flex-layout--height-auto" : undefined

    return (
      <FlexLayout
        direction="column"
        testID={this.props.testID}
        dangerousClassName={dangerousClassName}
      >
        {this.interweaveComponentsWithSpacer(components)}
      </FlexLayout>
    )
  }

  public render() {
    return this.props.hideImage
      ? this.renderWithouImage()
      : this.renderWithImage()
  }
}
